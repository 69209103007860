.join-waitlist-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
  margin-top: 40px;
}

.join-waitlist-page h1 {
  font-size: 32px;
  margin-bottom: 10px;
  color: #000000;
}

.join-waitlist-page p {
  color: #000000;
  margin-bottom: 30px;
}

/* New styles for the form */
.join-waitlist-page form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.join-waitlist-page form p {
  margin: 0;
}

.join-waitlist-page label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: bold;
  color: #000000;
}

.join-waitlist-page input,
.join-waitlist-page textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-family: inherit;
}

.join-waitlist-page textarea {
  min-height: 100px;
  resize: vertical;
}

.join-waitlist-page button[type="submit"] {
  background-color: #000000;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.join-waitlist-page button[type="submit"]:hover {
  background-color: #333333;
}

/* Adjust margin for mobile */
@media screen and (max-width: 768px) {
  .join-waitlist-page {
    padding: 20px 15px;
    margin-top: 30px;
  }
  /* Rest of your mobile styles... */
}
