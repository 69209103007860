body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: white;
  color: black;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 1px solid black; /* Changed from #eaeaea to black */
  background-color: white;
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000000;
}

.logo-image {
  height: 40px;
  width: auto;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-right: 10px; /* Small gap before Join Waitlist */
}

nav {
  display: flex;
  gap: 30px;
}

nav a {
  color: black;
  text-decoration: none;
  font-weight: 500;
}

.auth-buttons {
  display: flex;
  gap: 20px;
}

.auth-buttons a {
  text-decoration: none;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 4px;
}

.contact-sales {
  color: black;
}

.join-waitlist {
  color: black;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  margin-right: 10px;
}

/* Underline hover effect */
.join-waitlist::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: black;
  transition: width 0.3s ease;
}

.join-waitlist:hover::after {
  width: 100%;
}

main {
  flex-grow: 1;
  padding: 20px;
}
