body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.home-container {
  display: flex;
  padding: 160px 40px; /* Increased from 80px to 160px */
  max-width: 1200px;
  margin: 0 auto;
  gap: 60px;
  margin-top: 80px; /* Added additional top margin */
}

.left-section {
  flex: 1;
}

.left-section h1 {
  font-size: 64px;
  line-height: 1.1;
  margin: 0;
  font-weight: bold;
  color: black;
}

.right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 20px; /* Optional: adds some space at the top of the right section */
}

.right-section p {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  color: #000000;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .home-container {
    flex-direction: column;
    padding: 20px;
    gap: 30px;
  }

  .left-section h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .right-section p {
    font-size: 16px;
  }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
  .home-container {
    padding: 15px;
  }

  .left-section h1 {
    font-size: 28px;
  }
}

.hero {
  margin-bottom: 40px;
}

h1 {
  font-size: 44px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
  color: #8e8ea0;
  margin-bottom: 20px;
}

.description {
  font-size: 20px;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto 40px;
}

.cta-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-button, .secondary-button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.cta-button {
  background-color: #6e2feb;
  color: white;
}

.cta-button:hover {
  background-color: #5a1fd4;
}

.secondary-button {
  background-color: transparent;
  color: #6e2feb;
  border: 1px solid #6e2feb;
}

.secondary-button:hover {
  background-color: rgba(110, 47, 235, 0.1);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
}

nav {
  display: flex;
  gap: 1.5rem;
}

nav a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.auth-buttons {
  display: flex;
  gap: 1rem;
}

.auth-buttons button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.contact-sales, .sign-in {
  background-color: transparent;
  color: #333;
}

.sign-up {
  background-color: #5a2ca0;
  color: white;
}

.main-content {
  padding-top: 70px; /* Adjust based on header height */
}
